<template>

  <div class="container relative p-5 sm:p-0">
    <statusbar/>
    <div class="flex top">

      <logo/>
      <navigation/>
    </div>
    <main>
      <router-view/>

    </main>

  </div>
  <Footer/>

</template>


<style scoped lang="scss">
.top {
  justify-content: space-between;
}

.hero {
  position: absolute;
  z-index: 200;
  width: 100vw;
  height: 100vh;
}
</style>
<script>
import Logo from "@/components/Logo";
import Navigation from "@/components/Navigation"
import Statusbar from "@/components/Statusbar"
import Footer from "@/components/Footer"


export default {
  components: {Logo, Navigation, Statusbar, Footer}
}
</script>