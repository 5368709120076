<template>
  <router-link to="/">
<pre class="">
███╗   ███╗██████╗  ██████╗  ██████╗  ██████╗ ███╗   ██╗
████╗ ████║██╔══██╗██╔═══██╗██╔════╝ ██╔═══██╗████╗  ██║
██╔████╔██║██████╔╝██║   ██║██║  ███╗██║   ██║██╔██╗ ██║
██║╚██╔╝██║██╔══██╗██║   ██║██║   ██║██║   ██║██║╚██╗██║
██║ ╚═╝ ██║██║  ██║╚██████╔╝╚██████╔╝╚██████╔╝██║ ╚████║
╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝
</pre>
  </router-link>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>
pre {
  color: #FFFF00;
  font-size: .3rem;


}
@media (min-width: 640px){
  pre{
    font-size: 0.6rem;
  }
}
</style>