<template>
  <!-- Sidenav -->
  <nav class="tui-sidenav active" v-if="menuVisible" v-on:click="toggleMenu">

  <menu-items/>
  </nav>

  <!-- Sidenav active button -->
  <div class="tui-sidenav-button sm:invisible" v-on:click="toggleMenu">{{ menuIcon }}</div>


</template>

<script>
import menuItems from "@/components/MenuItems";
export default {
  name: "Navigation",
  components: {menuItems},
  data() {
    return {
      menuVisible: false,
    }
  },
  methods: {
    toggleMenu: function () {
      this.menuVisible = !this.menuVisible;
    },

  },
  computed:{
    menuIcon: function () {
      if (this.menuVisible) {
        return 'x'
      } else {
        return  '≡'
      }
    }
  }


}

</script>

<style lang="scss" scoped>

.tui-sidenav-button {
  font-size: 3rem;
  line-height: 2rem;
}
</style>