<template>
  <div class="home tui-window tui-border-double w-full my-5">
    <h1>I code with passion</h1>

    <p>
      My name is Marek.
      </p><p>
      I was born a long time ago far, far away. Not so much later I have started to learn
      how to
      build websites.
    <br/>
    Today I am good at this, and every day I am getting better.
    </p>
    <router-link to="about">
      <button class="m-3 tui-button">Read more</button>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Home',

}
</script>
<style scoped lang="scss">
.home {
}

button {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
p{
  margin: 1.3rem;
}
</style>