<template>
  <div class="tui-statusbar container">
    <p>Powered by mix of caffeine and passion.</p>
  </div>
</template>

<script>

export default {
  name: "Footer",

}

</script>

<style lang="scss" scoped>
.tui-statusbar {
  left: 50%;
  transform: translate(-50%);
  color: #000000;
  font-size: .85rem;
  text-align: center;
  z-index: -5;
}

</style>