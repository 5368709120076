<template>
  <ul>
    <li>
      <router-link to="/">Home</router-link>
    </li>
    <li>
      <router-link to="About">About</router-link>
    </li>
    <li>
      <router-link to="Projects">Projects</router-link>
    </li>
    <li>
      <router-link to="Skills">Skills</router-link>
    </li>
    <li>
      <router-link to="Contact">Contact</router-link>
    </li>

  </ul>

</template>

<script>
export default {
  name: "Menuitems",

}

</script>

<style lang="scss" scoped>

li {
  &:first-letter {
    color: #A80000;
  }

  color: yellow;
  list-style-type: none;
}
.router-link-active{
  background-color: #00a800;
}

</style>