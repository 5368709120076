import {createApp} from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import './css/main.scss'
import 'tuicss'

createApp(App)
    .use(router)

    .mount('#app')
