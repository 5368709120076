<template>
  <nav class="tui-nav relative mb-2">
    <menu-items/>
  </nav>

</template>

<script>
import menuItems from "@/components/MenuItems";

export default {
  name: "Statusbar",
  components: {menuItems}

}

</script>

<style lang="scss" scoped>
.tui-nav {
  position: absolute;
  width: 100%;
  background: none;

  &:hover {
    background: #A8A8A8;
  }

}

@media (max-width: 640px) {
  .tui-nav {
    display: none;
  }
}


</style>